import React, { useEffect, useState } from 'react';

import RycomTable from '../common/RycomTable'
import ProgressBar from '../common/ProgressBar'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomTableHeader from '../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import * as TaskService from '../../services/TaskService';
import environment from '../../environment'
import MenuItem from '@material-ui/core/MenuItem';
import { RycomGridItem, RycomGrid } from '../common/RycomGrid';
import RycomSelectField from '../common/RycomSelectField';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
      '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '50ch',
      },
  },
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
    formControl: {
      margin: theme.spacing(5),
    },
}));
export default function TaskList (props) {
  const classes = useStyles();
    const [tasks, setTasks] = useState([]);
    const [taskList,setTaskMenuList] = useState([]);
    const [taskStatus, setTaskStatus] = useState([]);
    const [taskDetailStatus, setTaskDetailStatus] = useState([]);
    const [detailTaskName, setDetailTaskName] = useState();
    const [selectedTaskId, setSelectedTaskId] = useState(undefined);
    const [selectedJob, setselectedJob] = useState(null);
    const [taskDetails, setTaskDetails] = useState(null);

    const { register, errors, handleSubmit, reset, watch, control } = useForm({ });

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(20);
  
    const loadTaskDetail=(taskStatus)=>{
      console.log("taskStatusId");
      console.log(taskStatus.id);





      TaskService.getAllTaskStatusDetail(taskStatus.id).then((res) => {
        if (res && res.data && res.data) {
          const taskDetailStatus = res.data;
          console.log("taskDetailStatus");
          console.log(taskDetailStatus);
         setTaskDetailStatus( taskDetailStatus );
          setDetailTaskName("Task Execution Details about "+taskStatus.taskName+" executed at "+taskStatus.taskExecutionStartedTime)
        }


      })
    }

    useEffect(() => {
      setLoading(true);
      let increamentedValue=progress
      const interval =  setInterval(function(){
        increamentedValue++;
        setProgress(increamentedValue);
      },12000)
      const userInfoJson=localStorage.getItem("userInfo"); 
    const userInfo=JSON.parse(userInfoJson);
    const parentcompanyid=userInfo['parentcompanyid'];
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

      // TaskService.getAllTask(parentcompanyid,  
      //   {
      //     onDownloadProgress: progressEvent => {
      //       const percentage = Math.round(
      //         (progressEvent.loaded * 100) / progressEvent.total
      //       );
      //       if (percentage === 100) {
      //         setProgress(100);
      //         clearInterval(interval);
      //         setTimeout(() => {
      //           setLoading(false);
      //         }, 400);
      //       }
      //     }
      //   })

      const taskStatusInterval = setInterval(() => {
        TaskService.getAllTaskStatus().then((res) => {
          if (res && res.data && res.data) {
            const taskStatus = res.data;
            setTaskStatus( taskStatus );
          }
        }) }, 12000);
     

       
      TaskService.getAllTask(paramID,  
        {
          onDownloadProgress: progressEvent => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (percentage === 100) {
              setProgress(100);
              clearInterval(interval);
              setTimeout(() => {
                setLoading(false);
              }, 120000);
            }
          }
        })  
        .then((res) => {
          if (res && res.data && res.data) {
            let data = JSON.parse(JSON.stringify(res.data));
            setTaskMenuList( data );
            const tasks = res.data;
            console.log('tasklistTODAY',tasks);
            setTasks( tasks );
          }
        })
        .catch((error) => {
          setLoading(false);
          setProgress(0);
          clearInterval(interval);
          console.error('Task =>', error)
          alert('Task Retrieval Error');
        })
        return () => clearInterval(taskStatusInterval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      }, []);


      const getTaskDetails=(taskId)=>{
        if (taskId) {
          TaskService.getTaskById(taskId)
              .then((res) => {
                  if (res && res.data && res.data) {
                    console.log('taskDetailsData',res.data)
                     // setTaskDetails(res.data);
                      setTaskStatus(res.data);
                  }
              })
              .catch((error) => {
                  console.error('getTaskDetails =>', error)
              })
        }
    
      }

  
    const columns = [
      { id: 'taskname', label: 'Task Name', minWidth: 170 },
      { id: 'taskType', label: 'Task Type', minWidth: 170 },
      { id: 'schedulerStatus', label: 'Job Status', minWidth: 170 },
      { id: 'companyName', label: 'Company', minWidth: 100 },
      { id: 'schedulerName', label: 'Scheduler', minWidth: 100 },
      { id: 'connectionName', label: 'Connection', minWidth: 100 },
      { id: 'dataMapperName', label: 'Data mapper', minWidth: 100 }
    ];

    const taskStatusColumns = [
      { id: 'taskName', label: 'Task Name', minWidth: 120 },
      { id: 'taskType', label: 'Task Type', minWidth: 50 },
    //  { id: 'totalNumberOfRecordsRetrieved', label: 'Retrieved Records', minWidth: 100 },
    //  { id: 'totalNumberOfRecordsProcessed', label: 'Processed Records', minWidth: 100 },
    //  { id: 'totalNumberOfRecordsStored', label: 'Stored Records', minWidth: 100 },
    //  { id: 'totalNumberOfFailedRecords', label: 'Failed Records', minWidth: 100 },
      { id: 'taskExecutionStartedTime', label: 'Started Time', minWidth: 100 },
    //  { id: 'taskExecutionCompletedTime', label: 'Completed Time', minWidth: 100 },
    //  { id: 'currentJobStatus', label: 'Status', minWidth: 50 },
      { id: 'moreInfo', label: 'Details', minWidth: 50 },
    ];
    

    const taskStatusDetailsColumns = [
      { id: 'fileName', label: 'Batch Name', minWidth: 50 },
      { id: 'numberOfRecordsRetrieved', label: 'Retrieved Records', minWidth: 100 },
      { id: 'numberOfRecordsProcessed', label: 'Processed Records', minWidth: 100 },
      { id: 'numberOfRecordsStored', label: 'Stored Records', minWidth: 100 },
      { id: 'numberOfDuplicateRecords', label: 'Duplicate Records', minWidth: 100 },
      { id: 'numberOfHistoricalRecords', label: 'Historical Records', minWidth: 100 },
      { id: 'numberOfFailedRecords', label: 'Failed  Records', minWidth: 100 },
      { id: 'message', label: 'Message', minWidth: 50 },
      { id: 'batchCurrentStatus', label: 'Status', minWidth: 50 },

    ];
    const taskMenuList = taskList?taskList.map((t, index) => <MenuItem  key={++index} value={t.taskname}> {t.taskname}</MenuItem>):[];
     console.log('taskListData=>',taskList.taskname);

    const rows=[...tasks].map((task)=>{
             task.taskname=<Link to={`/topics/task/detail/${task.taskid}`} >{task.taskname}</Link>
                                                     return task;
                                              });


      const taskStatusRows=taskStatus.map((status)=>{
        status.moreInfo=<Link to={`/topics/task/status/${status.id}`}>Get More Details</Link>
        //console.log('TASKSTATUAS',status)
               //  status.moreInfo=<button onClick={()=>loadTaskDetail(status)}>More Info</button>
                return status;
              });
              const taskStatusData = taskStatus.filter((status) =>{
                const taskName = status.taskName===taskDetails;
                  return taskName;
              })
              const jobStatusData = taskStatusData?.filter((status) => {
                const jobstatus = status.currentJobStatus===selectedJob;
                  return jobstatus;
              })
              const onlyJobStatusData = taskStatus.filter((status) =>{
                const onlyjobstatus = status.currentJobStatus===selectedJob;
                  return onlyjobstatus;
              })
      
      return (<div>
         {console.log('taskStatusData',taskStatusData)}
        {/* {console.log('Filter task >>',taskStatusRows[0]?.filter(obj => obj===taskDetails))} */}
        <RycomPageHeader title="Task List" links={[{title:'Add Task',url:'/topics/task/form',role:"create:task"}]}></RycomPageHeader>
        <RycomTableHeader title="Task" buttonTitle="Add Task"></RycomTableHeader>
        {loading && <ProgressBar percentage={progress} />}

        <RycomTable rows={rows} columns={columns}></RycomTable>
        <form className={classes.root}  >
        <RycomGrid>
                <RycomGridItem>
                        <RycomSelectField id="taskname"
                            label="Task Name"
                            name="taskname"
                            onChangeHandler={(event) => {
                              if(event[0].target.value!=='none')
                              {                            
                                setSelectedTaskId(event[0].target.value);
                                setTaskDetails(event[0].target.value);
                                  //getTaskDetails(event[0].target.value);
                                  console.log('setSelectedTaskIdDroptdown',event[0].target.value)
                                  return event[0].target.value
                              }else
                              {
                                setSelectedTaskId(null);
                                setTaskDetails(null);
                              }
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Task Name is required'
                                }
                            }}
                        >
                          <MenuItem key="0" value="none">Select Task Name</MenuItem>
                            {taskMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="jobstatus"
                            label="Job Status"
                            name="jobstatus"
                            onChangeHandler={(event) => {
                              if(event[0].target.value!=='none')
                              {
                              setselectedJob(event[0].target.value);
                                //setTaskDetails(event[0].target.value);
                                return event[0].target.value;
                              }else
                              {
                                setselectedJob(null);
                              }
                            }}
                            register={register}
                            control={control}
                            >
<MenuItem key="0" value="none">Select Task Status</MenuItem>
<MenuItem key="1" value="Task Processing Completed">Task Processing Completed</MenuItem>
<MenuItem key="2" value="Graph Process Initiated"> Graph Process Initiated</MenuItem>
<MenuItem key="3" value="Data Mapping Process Initiated"> Data Mapping Process Initiated</MenuItem>
<MenuItem key="4" value="Binding Failure"> Binding Failure</MenuItem>
                        </RycomSelectField>
                    </RycomGridItem>  
                    </RycomGridItem>
                    </RycomGrid></form>

        <RycomTableHeader title="Task Status"></RycomTableHeader>
        <RycomTable rows={taskDetails!==null && selectedJob === null ?taskStatusData:taskDetails=== null && selectedJob!== null ? onlyJobStatusData: taskDetails!==null && selectedJob!== null ? jobStatusData: taskStatusRows} columns={taskStatusColumns}></RycomTable>
        


      </div>);
    
}
