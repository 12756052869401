import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader'
import * as DocumentClassService from '../../services/DocumentClassService';

import { RycomGridItem, RycomGrid } from '../common/RycomGrid'
import Button from '@material-ui/core/Button'

import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom'
import * as TaskService from '../../services/TaskService';
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'

import RycomTable from '../common/RycomTable'
import RycomTableHeader from '../common/RycomTableHeader'
import { useForm } from 'react-hook-form';



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  details: {
    flexDirection: 'column',
  },

  aocard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'color-white',
    border: '1px solid ui-border-color-base',
    borderRadius: 'border-radius-base',
    marginBottom: 'spacer',
    padding: 0
   },
   
   title: {
      margin: 0,
     fontSize: 'xx-large',
     display: 'inline-block',
     paddingRight: '10px',
    },

   header: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px',
      borderBottom: '5px solid $ui-border-color-base'
    },

    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'flex-end'
    },  

    buttonStyle: {
      paddingRight: '5px',
    }

}));


export default function TaskStatusDetail() {
    const history = useHistory();
    const classes = useStyles();
    let { id } = useParams();
    const [taskValues, setTaskValues] = useState();
    const [taskIdValues, setTaskIdValues] = useState("");
    const [vertexDetails, SetVertexDetails] = useState();

    const [taskStatus, setTaskStatus] = useState();
    const [taskDetailStatus, setTaskDetailStatus] = useState([]);
    let taskvalueTypes ="";

    useEffect(() => {
        console.log(id)
        TaskService.getTaskStatusDetail(id).then((res) => {
            if (res && res.data && res.data) {
             console.log("taskDetailStatus");
              console.log(res.data);
              if(res.data.taskDisplayModel?.vertexTypeId!=='')
              {
                  DocumentClassService.getVertexById(res.data.taskDisplayModel?.vertexTypeId)
                          .then((res) => {
                              if (res && res.data && res.data) {
                                  console.log("res.data")
                                  console.log(res.data)
                                  SetVertexDetails(res.data);
                              }
                          })
                          .catch((error) => {
                              console.error('getVertexById =>', error)
                          });
              }

              setTaskIdValues(res.data.taskStatusModel.taskId)
              setTaskStatus(res.data.taskStatusModel);
             setTaskValues(res.data.taskDisplayModel);   
             setTaskDetailStatus(res.data.batchStatusRecords);
            }
        });
      //  return () => clearInterval(taskStatusInterval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);

/*
    const taskStatusInterval = setInterval(() => {
        TaskService.getTaskStatusDetail(id).then((res) => {
            if (res && res.data && res.data) {
              console.log("taskDetailStatus");
              console.log(res.data);
              setTaskStatus(res.data.taskDetails);
             setTaskValues(res.data);   
             setTaskDetailStatus( res.data.taskDetailStatus );
            }
        });
       }, 18000);*/



    const taskStatusName='Batch Status';
    const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/task"><Button
    color="primary" variant="contained" size="medium">
    Back</Button></Link></span>;

if(taskValues?.taskStorageType==="GraphConnector")
    {
        taskvalueTypes = <RycomInfoPair label="Vertex Type" value={vertexDetails ? vertexDetails.className : ''}></RycomInfoPair>
    }

  const taskStatusDetailsColumns = [
    { id: 'fileName', label: 'Batch Name', minWidth: 50 },
    { id: 'numberOfRecordsRetrieved', label: 'Retrieved Records', minWidth: 100 },
    { id: 'numberOfRecordsProcessed', label: 'Processed Records', minWidth: 100 },
    { id: 'numberOfRecordsStored', label: 'Stored Records', minWidth: 100 },
    { id: 'numberOfDuplicateRecords', label: 'Duplicate Records', minWidth: 100 },
    { id: 'numberOfHistoricalRecords', label: 'Historical Records', minWidth: 100 },
    { id: 'numberOfFailedRecords', label: 'Failed  Records', minWidth: 100 },
    { id: 'message', label: 'Message', minWidth: 50 },
    { id: 'batchCurrentStatus', label: 'Status', minWidth: 50 },
  ];


    return (<div>
        <Card className={classes.aocard}>
            <div className={classes.header}>
            <span className={classes.title} >
            {taskValues ? `Consolidated Task Status Details: ${taskValues.taskname}` : 'Consolidated Task Status Details'} </span>
            <span className={classes.toolbar}>
            {buttonLinks}
            </span>
            </div>
        </Card>
        <RycomSectionHeader title="Task Status Report"></RycomSectionHeader>
      <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task" value={taskValues ? taskStatus.taskName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task Type" value={taskValues ? taskValues.taskType : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label=" Organisation" value={taskValues ? taskValues.companyName : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Retrieved Records" value={taskStatus ? taskStatus.totalNumberOfRecordsRetrieved : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Processed Records" value={taskStatus ? taskStatus.totalNumberOfRecordsProcessed : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Stored Records" value={taskStatus ? taskStatus.totalNumberOfRecordsStored : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Failed Records" value={taskStatus ? taskStatus.totalNumberOfFailedRecords : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Started Time" value={taskStatus ? taskStatus.taskExecutionStartedTime : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Completed Time" value={taskStatus ? taskStatus.taskExecutionCompletedTime : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Status" value={taskStatus ? taskStatus.currentJobStatus : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                {taskvalueTypes}
            </Grid>
        </Grid>
        <RycomTableHeader title={taskStatusName}></RycomTableHeader>
        <RycomTable rows={taskDetailStatus} columns={taskStatusDetailsColumns}></RycomTable>
    </div>
    );
}
